<script>
import Vue from 'vue'
import Base from './Base.vue'
import pubsub_mixin from '@/shared/pubsub_mixin'

export default {
  name: 'StaffCalendar',
  extends: Base,
  props: {
    clientId: {
      type: Number
    }
  },
  mixins: [pubsub_mixin],
  data() {
    return {
      assignedJobsPerTeam: {},
      jobIdToAssign: null,
      teams: [],
      numberOfReservations: 0,
      unassignedJobs: {},
      cleanersWithIncompleteJobs: [],
      requests: {},
      dataGroup: 0,
      teamIds: [],
      reservationIds: [],
      loadedReservations: 0
    }
  },
  computed: {
    counts() {
      return {
        teams: { total: this.teamIds.length, loaded: this.teams.length },
        visits: { total: this.reservationIds.length, loaded: this.loadedReservations }
      }
    }
  },
  mounted() {
    this.subscribeToUpdates()
  },
  methods: {
    assignCleaner(jobId) {
      this.jobIdToAssign = jobId
    },
    unassignCleaner({ jobId, notify }) {
      var other_args = ''
      if (notify) {
        other_args = '&notify=1'
      }
      this.$http.delete('/api/cleaning_jobs/' + jobId + '?context=grid' + other_args).then(response => {
        this.refreshData()
      }, response => {
        this.$flasher.error(response.data.error)
      })
    },
    addCleaner(idx, data) {
      Vue.set(this.teams, idx, data)
    },
    addAssignedJob(data) {
      const assignedToId = data.assigned_to_id
      const hour24 = data.hour24
      if (this.assignedJobsPerTeam[assignedToId] == undefined) {
        Vue.set(this.assignedJobsPerTeam, assignedToId, {})
      }
      if (this.assignedJobsPerTeam[assignedToId][hour24] == null) {
        Vue.set(this.assignedJobsPerTeam[assignedToId], hour24, [])
      }
      this.assignedJobsPerTeam[assignedToId][hour24].push(data)
    },
    addUnassignedJob(data) {
      if (this.unassignedJobs[data.hour24] == null) {
        Vue.set(this.unassignedJobs, data.hour24, [])
      }
      this.unassignedJobs[data.hour24].push(data)
    },
    addVisit(idx, data) {
      this.loadedReservations += 1
      if (data.assigned) {
        this.addAssignedJob(data)
      } else {
        this.addUnassignedJob(data)
      }
    },
    loadData() {
      if (this.isVisitsView) {
        console.log("staffCalendar loadData for visitsView not run")
        return
      }
      if (this.isStaff && this.isMonthView) {
        console.log("staffCalendar loadData for monthview and staff is running")
        this.loading = false
        Array.prototype.forEach.call(this.daysByWeek, (weeks) => {
          Array.prototype.forEach.call(weeks, (dayData) => {
            dayData.eventData = []
            dayData.loading = true
            this.loadVisitForDate(dayData)
          })
        })

        return
      }

      console.log("staffCalendar loadData -> loadCleaners")
      this.loadCleaners()
    },
    loadCleaners() {
      if (this.teams.length > 0) {
        console.log("staffCalendar loadCleaners already initialized")
        return
      }
      console.log("staffCalendar loadCleaners")
      let localParams = Object.assign({}, this.params)
      localParams.group = 1
      this.loading = true
      this.$http.get(this.baseUrl, {
        params: localParams
      }).then(response => {
        if (response.data.teams) {
          if (response.data.teams.ids) {
            this.teamIds = response.data.teams.ids
            Array.prototype.forEach.call(response.data.teams.ids, (teamId, idx) => {
              this.$http.get(this.baseUrl, { params: { cleaner_id: teamId } }).then(response => {
                this.addCleaner(idx, response.data)
              }, response => {
                console.log("loadCleaners ERROR")
              })
            })
          }
        }
        this.loading = false
      }, response => {
        this.loading = false
        console.error(response.body)
        this.$flasher.error('Could not load the data')
      })
    },
    processLoadedDataForGrid(data) {
      if (data.group == 2) {
        this.assignedJobsPerTeam = {}
        this.loadedReservations = 0
        this.unassignedJobs = {}
      }
      if (data.requests) {
        this.requests = data.requests
      }
      if (this.cleanersWithIncompleteJobs) {
        this.cleanersWithIncompleteJobs = data.cleaners_with_incomplete_jobs
      }

      if (data.reservation_ids) {
        this.reservationIds = data.reservation_ids
        Array.prototype.forEach.call(data.reservation_ids, (visitId, idx) => {
          this.$http.get(this.baseUrl, { params: { visit_id: visitId } }).then(response => {
            this.addVisit(idx, response.data)
          }, response => {
           console.log("staffCalendar processLoadedDataForGrid ERROR")
          })
        })
      }
    },
    subscribeToUpdates() {
      if (!this.subscribed) {
        console.log("subscribing")
        this.fayeSubscribe('/reservations/**', 'client booked', (channel, msg) => {
          let channel_parts = channel.split('/')
          const action = channel_parts.pop()
          const visitId = channel_parts.pop()
          this.$flasher.notify('New Visit: ' + msg.info, 10)
          if (this.isStaff && this.isMonthView) {
          } else {
            if (this.date == msg.date) {
              this.$http.get(this.baseUrl, { params: { visit_id: visitId } }).then(response => {
                this.addVisit(0, response.data)
              }, response => {
               console.log("staffCalendar processLoadedDataForGrid ERROR")
              })
            }
          }
        })
      }
    }
  },
  watch: {
    cleanersWithIncompleteJobs() {
      this.showIncompleteJobsModal = this.cleanersWithIncompleteJobs && this.cleanersWithIncompleteJobs > 0
    }
  }
}
</script>
